import React from 'react';
import Navigation from '../components/Navigation';

const Home = () => {
return (
 <div className="home">
 		<Navigation />
	 	<div className='homeContent'>
	 		<div className='content'>
		 	<h1>Jean KONCZAK</h1>
		 	<h2>Product Owner</h2>
		 		<div className='pdf'>
					<a href='./media/cv.pdf' target='_blanck'>Télécharger CV</a>
		 		</div>
			 </div>	 
	 	</div>
 	</div>

	);
};

export default Home;