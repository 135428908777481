export const portfolioData = [
  {
    id: 1,
    name: 'Product Owner',
    languages: ['Informatique','Maintenance'],
    languagesIcons: ['Relation Client'],
    info: ['En relation avec la direction, le service commercial et technique : ',<br/>,
            " - Prise de besoins des clients ,",<br/>,
            " - Rédaction de cdc ,",<br/>,
            " - Création de backlog de produit ,",<br/>,
            " - Ajustement client avec l'équipe technique ,",<br/>,
            " - Test et recettage.",<br/>,],
    picture: './media/project1.JPG'
  },
  {
    id: 2,
    name: 'Consultant-Formateur',
    languages: ['Informatique','Maintenance'],
    languagesIcons: ['Birt, ','Mister Maint Fims, ','Mister Maint Fusion, ','Indicateurs personnalisés, ','Passerelle '],
    info: ['Consultant-Formateur : ',<br/>, 
    ' -	Conduire les projets clients de la phase avant-vente à la mise en production',<br/>,
    ' -	Apporter mon expertise aux commerciaux pour l’analyse des besoins des clients',<br/>,
    ' -	Définir les besoins des clients', <br/>, 
    "-	Réalisation d'audits, de formations et d’accompagnements en vue d’un déploiement complet des solutions ITM",<br/>,
    " -	Réalisation d’indicateurs sur mesure ",<br/>,
    " -	Formation au logiciel birt (logiciel de bi)",<br/>,
    " -	Prise de besoins, réalisation et mise en place d’interfaces entre GMAO et autres solutions techniques"],
    picture: './media/project2.JPG'
  },
  {
    id: 3,
    name: 'Formateur ',
    languages: ['Informatique','Maintenance'],
    languagesIcons: ['Formatateur ,','Relation Clients ,',"Conseils "],
    info: ["Formateur : ",<br/>, "-	Former les clients à l’utilisation des solutions de GMAO ITM ",<br/>,
    "-	Rencontrer des prospects et réaliser des démonstrations du logiciel sur site ou à distance ",<br/>,
    "-	Effectuer du support technique et de la hotline "],
    picture: './media/project3.JPG'
  },
  {
    id: 4,
    name: "Apprenti Ingénieur Maintenance : Projet de fin d'études",
    languages: ['Maintenance'],
    languagesIcons: ['5S, ','Pareto, ','Lean Manifacturing, ','Six Sigma ','Kanban, ', 'Gant, ', 'Brainstorming'],
    info: [ "Rattaché aux responsables maintenance et production",<br/>,
    
    "**Mise en place d’un système de gestion du magasin de pièces maintenance :",<br/>,
   " - Analyse de l’existant et définition des besoins (pièces critique)",<br/>,
    "- Gestion de ce projet sous msproject afin de tenir les délais et surveiller les écarts",<br/>,
    "- Réorganisation du magasin (Implication du personnel/ Animation de réunions et de groupes de travail (Brainstorming/ 5S/ Mise en place de management Visuel)",<br/>,
    "- Mise en place d’un système de gestion du stock",<br/>,
    "- Standardisation du fonctionnement (Rédaction et mise en place de procédures)",<br/>,
    "- Animation et formation des futurs utilisateurs",<br/>,
    "- Retour d'expérience",<br/>,<br/>,
    "Objectif :",<br/>,
    "+ Budget 22k€.",<br/>,
    "+ Rentabilisation : 2 mois.",<br/>,
    "+ Augmentation de la réactivité des maintenances curatives.",<br/>,
    "+ Réduction des surcouts liés aux pièces critiques.",<br/>,
    "+ Mise en place d’un magasin intuitif.",<br/>,
    "+ Réduction des taux de pannes.",<br/>,<br/>
    ,],
    picture: './media/project4.JPG'
  },
  {
    id: 5,
    name: 'Apprenti Ingénieur Maintenance',
    languages: ['Maintenance'],
    languagesIcons: ['VBA, ','GMAO, ','Lean Management'],
    info: [  "**Création et implantation d’un progiciel :",<br/>,
    "- Etude de l’existant et définition des besoins.",<br/>,
    "- Détermination de l'arborescence.",<br/>,
    "- Développement et intégration de l'outil de maintenance sous Visual Basic.",<br/>,
    "- Formation des techniciens et prise en compte des retours d’expériences",<br/>,<br/>,
    "Objectifs : ",<br/>,
    "+ Rapports de postes informatisés",<br/>,
    "+ Création d’indicateurs de suivi afin d’optimiser le préventif et de réduire le curatif",<br/>,
    "+ Analyse des modes de défaillance, de leurs effets et de leur criticité",<br/>,<br/>,
    "**Rédaction de plans de maintenance niveaux 1 et 2 :",<br/>,
    "- Etude de l’existant et Consultation des constructeurs pour voir les recommandations.",<br/>,
    "- Prise en compte de l’expérience des techniciens.",<br/>,<br/>,
   " **Missions Annexes :",<br/>,
    "- Coordinateur maintenance. (gestion d'équipes, planification de travaux, gestion des commandes, maîtrise des délais et des fournisseurs).",<br/>,
    "  - Modification d’une ligne de production suite à l’implantation d’un nouveau module.",<br/>,
    "  - Création de partenariats afin de réduire le nombre de partenaires commerciaux. (économie 8k€ )",<br/>,<br/>,],
    picture: './media/project9.JPG'
  }, 
  {
    id: 6,
    name: 'Technicien Qualité',
    languages: ['Autre'],
    languagesIcons: ['Métrologie, ','SAP, ', 'Gestion des non conformités'],
    info: ["Contrôleur Qualité:",<br/>,
    "- Contrôler la qualité des produits.",<br/>,
    "- Renseigner les résultats dans SAP.",<br/>,
    "- Prendre des décisions en cas de non-conformité avec le chef d'équipe."],
    picture: './media/project7.JPG'
  },
  {
    id: 7,
    name: 'Stage BTS ATI',
    languages: ['Autre'],
    languagesIcons: ['Standardisation'],
    info: ["Stage dans le cadre de mes études de BTS Assistance Technique d'Ingénieur:",<br/>,
    "- Réalisation d'un projet consistant à la mise en place d'une cuve de récupération des déchets industriel et recherche d'une entreprise permettant de les revaloriser.",<br/>,
    "- Création et mise en place de fiches de procédure visant à la standardisation du travail des conducteurs."],
    picture: './media/project6.JPG'
  },
  {
    id: 8,
    name: "Mission à l'étranger",
    languages: ['Maintenance'],
    languagesIcons: ['Anglais, ','Autonomie, ',"Travail d'équipe"],
    info: ["- Formation au métier de technicien de maintenance.",<br/>,
    "- Optimisation de mes compétences linguistiques.",<br/>,
    "- Découverte de l'interculturalité"],
    picture: './media/project5.JPG'
  },
]