import React from 'react';

const Experience = () => {

return (

 <div className='experience'>  
    <h3>Formations</h3>
    <div className='exp-1'>
        <h4>Agile</h4>
        <h5> 2021</h5>
        <p>- Comprendre la démarche Agile <br/>
           - Devenir responsable de Produit Agile <br/>
           - Certification Scrum Product Owner</p>
    </div>
    <div className='exp-2'>
        <h4>Conduite de projets</h4>
        <h5> 2018</h5>
        <p>L'essenciel pour conduire un projet en informatique</p>
    </div>
    <div className='exp-3'>
        <h4>Birt</h4>
        <h5> 2016</h5>
        <p>Formation BIRT Report Designer : Génération d'états d'impression avec le logiciel Birt développé par Eclipse</p>
    </div>
    <div className='exp-4'>
        <h4> Ingénieur Généraliste</h4>
        <h5> 2010 – 2013</h5>
        <p>Formation d'ingénieur généraliste par alternance à l'école du CESI à ARRAS.</p></div>
    <div className='exp-5'>
        <h4>BTS ATI (Assistance Technique d'Ingénieur)</h4>
        <h5> 2009-2010</h5>
        <p>BTS ATI (Assistance Techniques d'Ingénieur) au lycée Baudimont St-Charles à Arras.</p>
    </div>
    <div className='exp-6'>
        <h4>BAC STI</h4>
        <h5> 2007-2008</h5>
        <p>BAC STI (Sciences et Technologies Industrielles génies de l’électrotechnique (avec mention Bien)) au lycée Baudimont St-Charles à Arras.</p>
    </div>
    <div className='exp-7'>
        <h4>BEP ELEC</h4>
        <h5> 2005-2006</h5>
        <p>BEP des métiers de l’électrotechnique au lycée Baudimont St-Charles à Arras.</p>
</div>
 </div>
	);
};

export default Experience;