import React, { Component } from 'react';
import ProgressBar from './ProgressBar';

import Listhobbis from './ListHobbis';

class languages extends Component{
    state={
            languages:[
        {id: 1, value: 'Mister Maint', xp: 2}, 
        {id: 2, value: 'Word', xp: 1.8},    
        {id: 3, value: 'Excel', xp:1.8},
        {id: 4, value: 'Birt', xp: 1.5},    
        {id: 5, value: 'Mysql', xp: 1.3},    
        {id: 6, value: 'SQLserver', xp:1.3},
        {id: 7, value: 'Anglais', xp: 1}, 
        {id: 8, value: 'VBA', xp:1},
        {id: 9, value: 'PHP', xp: 1},     
        {id: 10, value: 'Open Project', xp: 1}, 
        {id: 11, value: 'Photoshop', xp: 0.7} ],
            hobbies:[
        {id: 1, value: ' Mecanique auto (wankel)', fafa:'fas fa-car'} ,
        {id: 2, value: ' Bricolage', fafa:'fas fa-tools'} ,
        {id: 3, value: ' Nouvelles technologies', fafa:'fas fa-check-square'} ,
            ],
            Competences:[
        {id: 1, value: ' GMAO', fafa:'fas fa-check-square'} ,
        {id: 2, value: ' Lean Management', fafa:'fas fa-check-square'} ,
        {id: 3, value: ' 6 Sigma', fafa:'fas fa-check-square'} ,
        {id: 4, value: ' Gestion de projets Industriel', fafa:'fas fa-check-square'} ,
        {id: 5, value: ' Gestion de projets Informatique (Agile)', fafa:'fas fa-check-square'} ,

            ]
    }

        render(){
            let {languages,hobbies,Competences} = this.state;

        return (
            <div className='languagesFrameworks'>
                <ProgressBar    
                    className='languagesDisplay'
                    languages={languages}
                    title='languages / Logiciels'
                />
                <Listhobbis    
                    className='hobbies'
                    languages={hobbies}
                    title='Hobbies'
                />
                 <Listhobbis    
                    className='Competences'
                    languages={Competences}
                    title='Autres compétences'
                />

            </div>
        
            );
        }
}



export default languages;