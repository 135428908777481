import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import Home from './pages/Home';
import PortFolio from './pages/PortFolio';
import Contact from './pages/Contact';
import Knowledges from './pages/Knowledges';
import NotFound from './pages/NotFound';

const App = () => {
return (
			 <>
				<BrowserRouter>
					<Switch>
						<Route path='/' exact component={Home} />
						<Route path='/competences' component={Knowledges} />
						<Route path='/PortFolio' component={PortFolio} />
						<Route path='/Contact' component={Contact} />
						<Route component={NotFound} />
					</Switch>
				</BrowserRouter>
			</>

	);
};

export default App;