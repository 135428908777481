import React, {Component} from "react";

export default class Project extends Component{
    state={
        showInfo:false
    }

handleInfo = () => {
    this.setState({
        showInfo:!this.state.showInfo
    })
 }


        

    render(){
        let {name,languagesIcons,  info, picture} = this.props.item;

      return(
       <div className="project">
            <div className="icons"><p>
                {languagesIcons.map(icon =>
                    <span key={icon}>{icon}</span>
                )}
           </p> </div>
            <h3>{name}</h3>
            <img src={picture} alt="logo" onClick={this.handleInfo} />
            <span className="infos" onClick={this.handleInfo} >
             <i className="fas fa-plus-circle"></i>

            </span>
                {
                    this.state.showInfo && (
                        <div className='showInfos'>
                                <div className='infosContent'>
                                    <div className='head'>
                                        <h2>{name}</h2>
                                     
                                    </div>
                                    <p className="text">{info}</p>
                                    <div className="button retourn" onClick={this.handleInfo}>Retourner sur la page</div>
                                </div>
                            </div>
                    )
                }

        </div>
      );
    }
}