import React from 'react';
import Experience from '../components/knowledges/Experience';
import Navigation from '../components/Navigation';
import Languages from '../components/knowledges/Languages';



const Knowledges = () => {

return (
 <div className='knowledges'>
	 <Navigation />
	 <div className='knowledgesContent'>
		<Languages/>
		<Experience/>
		
	 </div>
 </div>

	);
};

export default Knowledges;