import React from 'react';
import {NavLink} from 'react-router-dom';

const Navigation = () => {

return (

 <div className='sidebar'>
     <div className='id'>
         <div className='idContent'>
            <img src="./media/photo.png" alt="ean KONCZAK - Consultant / Formateur / Product Owner / spécialisé dans la maintenance et les logciels de GMAO" />
            <h3> Jean KONCZAK</h3>
        </div>
     </div> 

 <div className='navigation'>
    <ul>
        <li>
            <NavLink exact to="/" activeClassName='navActive'>
             <i className='fas fa-home'></i>
            <span>Accueil</span>
            </NavLink>
        </li>
        <li>
            <NavLink exact to="/competences" activeClassName='navActive'>
             <i className='fas fa-mountain'></i>
            <span>Compétences</span>
            </NavLink>
        </li>
        <li>
            <NavLink exact to="/portfolio" activeClassName='navActive'>
             <i className='fas fa-images '></i>
            <span>Expériences</span>
            </NavLink>
        </li>
         <li>
            <NavLink exact to="/Contact" activeClassName='navActive'>
             <i className='fas fa-address-book'></i>
            <span>Contact</span>
            </NavLink>
        </li>
    </ul>
 </div>
 <div className='socialNetwork'>
    <ul>
        <li>
            <a href="https://www.facebook.com/Luditjean/" target="_blanck" rel="noopener noreferrer"><i className="fab fa-facebook-square"></i></a>
        </li>
        <li>
            <a href="https://www.linkedin.com/in/jean-konczak-74971944/" target="_blanck" rel="noopener noreferrer"><i className="fab fa-linkedin"></i></a>
        </li>
        <li>
            <a href="https://viadeo.journaldunet.com/p/jean-konczak-4938652" target="_blanck" rel="noopener noreferrer"><i className="fab fa-viadeo"></i></a>
        </li>
    </ul>
    <div className="signature">
    <p>Jean KONCZAK - 2022 - V2</p>
    </div>
 </div>
 </div>
	);
};

export default Navigation;