import React from 'react';

const Listhobbis = (props) =>{
    return(
<div className={props.className}> 
    <h3>{props.title}</h3>

    <div>
        {
          props.languages.map((item)=>{ 


              return (

              <div key={item.id} className='hobbies'>


                        <li className='hobby'><i className={item.fafa}></i><span>{item.value}</span></li>
                   </div>
                )
            } )  
        } 
    </div>
</div>

    );
};
export default Listhobbis;

